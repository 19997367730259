import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { arrayMove } from '@dnd-kit/sortable'
import { DragEndEvent } from '@dnd-kit/core'
import { CardState } from '../../types/card'

export const INITIAL_REQUIRED_CARDS = 4
type CardsAction =
  | { type: 'ADD_CARD' }
  | { type: 'DELETE_CARD'; payload: string }
  | { type: 'UPDATE_CARD'; payload: { id: string; updates: Partial<CardState> } }
  | { type: 'DRAG_END'; payload: DragEndEvent }
  | { type: 'SET_INITIAL_CARDS'; payload: CardState[] }

interface CardsState {
  cards: CardState[]
  initialCards: CardState[]
  successfulCards: string[]
  isFormModified: boolean
}

export const createInitialCards = (length: number): CardState[] =>
  Array.from({ length }, (_, index) => ({
    id: uuidv4(),
    order: index + 1,
    url: '',
    cta: '',
    title: '',
    posterUrl: '',
    isValidUrl: false,
    hasCustomCta: false,
    hasCustomTitle: false,
    mode: 'url',
  }))

const cardsReducer = (state: CardsState, action: CardsAction): CardsState => {
  switch (action.type) {
    case 'ADD_CARD':
      return {
        ...state,
        cards: [
          ...state.cards,
          {
            ...createInitialCards(1)[0],
            order: state.cards.length + 1,
          },
        ],
        isFormModified: true,
      }

    case 'DELETE_CARD':
      const filteredCards = state.cards.filter(card => card.id !== action.payload)
      const normalizedCards =
        filteredCards.length < INITIAL_REQUIRED_CARDS
          ? [...filteredCards, ...createInitialCards(INITIAL_REQUIRED_CARDS - filteredCards.length)].map(
              (card, index) => ({
                ...card,
                order: index + 1,
              }),
            )
          : filteredCards.map((card, index) => ({
              ...card,
              order: index + 1,
            }))

      return {
        ...state,
        cards: normalizedCards,
        isFormModified: true,
      }

    case 'UPDATE_CARD':
      return {
        ...state,
        cards: state.cards.map(card => (card.id === action.payload.id ? { ...card, ...action.payload.updates } : card)),
        isFormModified: true,
        successfulCards: state.successfulCards.filter(id => id !== action.payload.id),
      }

    case 'DRAG_END': {
      const { active, over } = action.payload

      if (active.id !== over?.id) {
        const oldIndex = state.cards.findIndex(card => card.id === active.id)
        const newIndex = state.cards.findIndex(card => card.id === over?.id)

        const reorderedCards = arrayMove(state.cards, oldIndex, newIndex).map((card, index) => ({
          ...card,
          order: index + 1,
        }))

        return {
          ...state,
          cards: reorderedCards,
          isFormModified: true,
        }
      }
      return state
    }

    case 'SET_INITIAL_CARDS':
      return {
        ...state,
        initialCards: action.payload,
        cards: action.payload,
        isFormModified: false,
        successfulCards: action.payload.some(card => card.url && card.title && card.cta)
          ? action.payload.map(card => card.id)
          : [],
      }

    default:
      return state
  }
}

const CardContext = React.createContext<
  | {
      state: CardsState
      dispatch: React.Dispatch<CardsAction>
    }
  | undefined
>(undefined)

export const CardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(cardsReducer, {
    cards: createInitialCards(INITIAL_REQUIRED_CARDS),
    initialCards: createInitialCards(INITIAL_REQUIRED_CARDS),
    successfulCards: [],
    isFormModified: false,
  })

  return <CardContext.Provider value={{ state, dispatch }}>{children}</CardContext.Provider>
}

export const useCardContext = () => {
  const context = React.useContext(CardContext)
  if (!context) {
    throw new Error('useCardContext must be used within a CardProvider')
  }
  return context
}
