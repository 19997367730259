import React from 'react'
import { useAPIContext } from '../../context/api'
import { VideoAPI } from '../../../../constructs/video-processing/interface'
import { v4 as uuidv4 } from 'uuid'

export const useUploadMedia = () => {
  const { callEndpoint } = useAPIContext()

  const uploadMedia = React.useCallback(
    async (fileType: 'video', fileExtension: string, file: File) => {
      try {
        const uuid = uuidv4()

        const path = `/${uuid}/video-mp4/video.mp4`

        const response = await callEndpoint<VideoAPI, 'post'>('upload', path, 'POST', {
          fileType,
          fileExtension,
        })

        const { uploadUrl, fileKey } = response

        if (!file || !file.type) {
          throw new Error('Archivo no válido o tipo de archivo no especificado.')
        }

        const s3Response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })

        if (!s3Response.ok) {
          const errorText = await s3Response.text()
          throw new Error(`Error subiendo archivo a S3: ${s3Response.status} - ${errorText}`)
        }

        return {
          fileKey,
          uuid,
          path: `/${uuid}/video-mp4/video.mp4`,
        }
      } catch (error) {
        console.error('Hook | Error al subir archivo:', error)
        throw error
      }
    },
    [callEndpoint],
  )

  return { uploadMedia }
}
