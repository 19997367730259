import React from 'react'
import { Modal, List, Button, Typography, Pagination, Input, Image } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useFetchVideos } from '../../hooks/list-videos'
import { VideoMetadata } from '../../../../types/videos'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

interface ReuseVideosModalProps {
  visible: boolean
  onClose: () => void
  onSelect: (video: VideoMetadata) => void
}

export const ReuseVideosModal: React.FC<ReuseVideosModalProps> = ({ visible, onClose, onSelect }) => {
  const { fetchVideos } = useFetchVideos()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [videos, setVideos] = React.useState<VideoMetadata[]>([])
  const [loading, setLoading] = React.useState(true)
  const pageSize = 6

  const getVideos = React.useCallback(async () => {
    setLoading(true)
    try {
      const videos = await fetchVideos()
      setVideos(videos)
    } catch (err) {
      console.error('Edit Form API Error:', err)
    } finally {
      setLoading(false)
    }
  }, [fetchVideos])

  React.useEffect(() => {
    if (visible) {
      getVideos()
    }
  }, [getVideos, visible])

  const filteredVideos = videos.filter(
    video =>
      !!(
        video.sk.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.caption?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.date?.includes(searchTerm)
      ),
  )

  const startIndex = (currentPage - 1) * pageSize
  const currentVideos = filteredVideos.slice(startIndex, startIndex + pageSize)

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Historial de videos</span>
          <Input
            placeholder='Buscar en el historial...'
            allowClear
            onChange={e => {
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
            style={{ width: 200, marginRight: '24px' }}
            suffix={<SearchOutlined style={{ color: '#000' }} />}
          />
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width={900}
    >
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={currentVideos}
        loading={loading}
        renderItem={video => (
          <List.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px',
                border: '1px solid #e8e8e8',
                borderRadius: '8px',
                position: 'relative',
                height: '180px',
              }}
            >
              {/* Miniatura del video */}
              <div style={{ width: '120px', height: '100%' }}>
                <Image
                  src={video.posterUrl}
                  width='100%'
                  height='100%'
                  preview={false}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '12px',
                    display: 'block',
                  }}
                />
              </div>

              {/* Información del video */}
              <div style={{ flex: 1, marginLeft: '12px', overflow: 'hidden' }}>
                {/* Fecha de publicación */}
                <Typography.Text
                  type='secondary'
                  style={{
                    fontSize: '10px',
                    marginBottom: '4px',
                    display: 'block',
                  }}
                >
                  Publicado: {dayjs(video.date).format('LL')}
                </Typography.Text>

                {/* Caption: mostrar solo un fragmento */}
                <Typography.Paragraph
                  style={{
                    margin: '4px 0',
                    fontStyle: 'italic',
                    fontSize: '10px',
                    color: '#595959',
                  }}
                >
                  {video.caption
                    ? video.caption.length > 120
                      ? video.caption.substring(0, 120) + '...'
                      : video.caption
                    : 'Sin título disponible'}
                </Typography.Paragraph>

                {/* URL */}
                <Typography.Link
                  href={video.videoUrl}
                  target='_blank'
                  style={{
                    fontSize: '10px',
                    display: 'block',
                    marginBottom: '4px',
                  }}
                >
                  URL: {video.originalUri || video.videoUrl}
                </Typography.Link>

                {/* CTA */}
                <Typography.Text style={{ fontSize: '10px' }}>CTA: {video.cta}</Typography.Text>
              </div>

              {/* Botón "Seleccionar" */}
              <Button
                type='primary'
                onClick={() => onSelect(video)}
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  fontSize: '10px',
                  height: '24px',
                  lineHeight: '22px',
                  padding: '0 8px',
                }}
              >
                Seleccionar
              </Button>
            </div>
          </List.Item>
        )}
      />

      {/* Agregar paginación */}
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredVideos.length}
        onChange={page => setCurrentPage(page)}
        style={{ textAlign: 'center', marginTop: '16px' }}
      />
    </Modal>
  )
}
