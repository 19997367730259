import React, { CSSProperties } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Menu } from 'lucide-react'

import { CardContent } from './card-content'
import { CardProps } from '../../../types/card'

export const SortableCard: React.FC<
  CardProps & {
    onDelete: (id: string) => void
  }
> = ({ content, required, onFieldChange, isSaved, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: content.id,
    disabled: false,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 'auto',
    position: 'relative',
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        {/* Drag handle */}
        <div
          {...attributes}
          {...listeners}
          style={{
            backgroundColor: '#EAECF5',
            cursor: 'move',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: 'auto',
            zIndex: 1,
            left: '-20px',
            padding: '7px',
            top: '0',
            bottom: '0',
            borderTopLeftRadius: '35px',
            borderBottomLeftRadius: '35px',
            marginBottom: '15px',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#363F72',
            }}
          >
            {` ${content.order}`}
          </span>
          <Menu style={{ color: '#363F72' }} width={14} />
        </div>

        {/* Card content */}
        <div style={{ flex: 1 }}>
          <CardContent
            content={content}
            required={required}
            onFieldChange={onFieldChange}
            isSaved={isSaved}
            onDelete={onDelete}
          />
        </div>
      </div>
    </div>
  )
}
