const MEDIA_DOMAIN = process.env.REACT_APP_MEDIA_DOMAIN

const getMediaDomain = (fileKey: string): string => {
  const parts = fileKey.split('/')

  const clientId = parts[1]
  const videoId = parts[2]

  return `${MEDIA_DOMAIN}/${clientId}/${videoId}.mp4`
}

export default getMediaDomain
