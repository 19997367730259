import React from 'react'
import { UploadFile } from 'antd/es/upload'
import { Card, Form, Input, Tooltip, Tabs, Upload, Button, Modal, Spin, Tag } from 'antd'
import { LinkOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { UploadIcon, Trash } from 'lucide-react'

import { CardProps, PosterDisplayState } from '../../../types/card'
import { MediaData } from '../../../../types/media-data'
import { VideoMetadata } from '../../../../types/videos'
import { useCardContext } from '../../context/card'

import { useFetchMedia } from '../../hooks/use-fetch-media'
import { useUploadMedia } from '../../hooks/carousel/upload-carousel'

import { SecondaryButton } from '../ui/secondary-button'
import { ReuseVideosModal } from './reuse-video-modal'
import { Poster } from './poster'
import { VALID_IG_LINK, VALID_TIKTOK_LINK } from '../../constants/media-platform'
import validateUrl from '../../utils/url-validator'
import getMediaDomain from '../../utils/get-media-domain'
import { tokens } from '../../theme/tokens'
import { typography } from '../../theme/typography'
import { StatusType } from '../../../../types/carousel'

const MEDIA_DOMAIN = process.env.REACT_APP_MEDIA_DOMAIN

export const CardContent: React.FC<CardProps> = React.memo(
  ({ content, required, onFieldChange, isSaved, onDelete }) => {
    const { state, dispatch } = useCardContext()
    const { uploadMedia } = useUploadMedia()
    const { fetchExternalMetadata } = useFetchMedia()

    const isLoaded = !!content.url
    const [reuseVideoModal, setReuseVideoModal] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [externalMetadata, setExternalMetadata] = React.useState<MediaData>({
      mediaUrl: '',
      caption: '',
      thumbnail: '',
    })
    const [uploadingVideo, setUploadingVideo] = React.useState(false)
    const [uploadedFileName, setUploadedFileName] = React.useState('')
    const [activeMode, setActiveMode] = React.useState(content.mode)

    const handleCancel = () => setDeleteModal(false)

    const handleDeleteClick = () => setDeleteModal(true)

    const handleConfirmDelete = () => {
      setDeleteModal(false)
      if (onDelete) {
        onDelete(content.id)
      }
    }

    // limpia el campo CTA si coincide con la URL cuando se enfoca
    const handleFocusCTA = React.useCallback(() => {
      if (content.cta === content.url) {
        onFieldChange(content.id, 'cta', '')
      }
    }, [content.cta, content.url, onFieldChange, content.id])

    // setea el campo CTA a la URL si está vacío cuando se desenfoca
    const handleBlurCTA = React.useCallback(() => {
      if (content.cta.trim() === '') {
        onFieldChange(content.id, 'cta', content.url)
      }
    }, [content.cta, content.url, onFieldChange, content.id])

    // setea el campo título el caption del video si está vacío cuando se desenfoca
    const handleBlurTitle = React.useCallback(() => {
      if (content.title.trim() === '') {
        onFieldChange(content.id, 'title', externalMetadata.caption)
      }
    }, [content.title, externalMetadata.caption, onFieldChange, content.id])

    // selecciona un video existente del historial
    const handleExistingVideoSelect = (selectedVideo: VideoMetadata) => {
      const videoUrl = selectedVideo.originalUri || selectedVideo.videoUrl || ''

      onFieldChange(content.id, 'url', videoUrl)
      onFieldChange(content.id, 'cta', selectedVideo.cta || '')
      onFieldChange(content.id, 'title', selectedVideo.caption || '')
      onFieldChange(content.id, 'posterUrl', selectedVideo.posterUrl || '')

      const isMediaUrl = videoUrl.includes(MEDIA_DOMAIN as string)

      const newMode = isMediaUrl ? 'upload' : 'url'
      dispatch({
        type: 'UPDATE_CARD',
        payload: {
          id: content.id,
          updates: {
            mode: newMode,
            status: selectedVideo.status,
          },
        },
      })

      setActiveMode(newMode)

      setReuseVideoModal(false)
    }

    // cambia el modo de contenido entre URL, subir archivo
    const handleModeChange = (mode: string) => {
      if (mode === 'history') {
        setReuseVideoModal(true)
        return
      }

      if (mode !== activeMode) {
        setActiveMode(mode as 'url' | 'upload')

        dispatch({
          type: 'UPDATE_CARD',
          payload: {
            id: content.id,
            updates: { mode: mode as 'url' | 'upload' },
          },
        })
      }
    }

    // maneja la subida de archivos
    const handleFileUpload = (fileType: 'video') => {
      return {
        name: fileType,
        multiple: false,
        accept: 'video/*',
        showUploadList: false,
        beforeUpload: async (file: UploadFile) => {
          try {
            setUploadingVideo(true)
            setUploadedFileName('')
            const fileToUpload = file as unknown as File

            if (!fileToUpload) {
              throw new Error('Archivo no válido')
            }

            const extension = file.name.includes('.') ? `.${file.name.split('.').pop()?.toLowerCase()}` : ''

            if (!fileToUpload.type) {
              throw new Error('Tipo de archivo no especificado.')
            }

            const res = await uploadMedia(fileType, extension, fileToUpload)

            if (res) {
              const domain = getMediaDomain(res.fileKey)
              setUploadedFileName(fileToUpload.name)
              onFieldChange(content.id, 'posterUrl', '')
              onFieldChange(content.id, 'url', domain)

              dispatch({
                type: 'UPDATE_CARD',
                payload: {
                  id: content.id,
                  updates: { mode: 'upload', status: 'PROCESSING' },
                },
              })
            }
          } catch (error) {
            console.error('CardContent | Error al subir archivo:', error)
          } finally {
            setUploadingVideo(false)
          }
          return false
        },
      }
    }

    const getPosterDisplayState = (): PosterDisplayState => {
      if (content.status === 'READY') return 'preview'

      if (activeMode === 'upload') {
        if (!content.url) return 'hidden'
        if (content.posterUrl) return 'preview'
        if (content.status === 'PROCESSING') return 'processing'
        return 'processing'
      }

      if (activeMode === 'url') {
        if (!content.url) return 'empty'

        const isMedia = VALID_IG_LINK.test(content.url) || VALID_TIKTOK_LINK.test(content.url)

        if (!isMedia) return 'processing'
        if (content.posterUrl || externalMetadata.thumbnail) return 'preview'
        return 'empty'
      }

      return 'hidden'
    }

    const getPosterImage = (state: PosterDisplayState): string | undefined => {
      switch (state) {
        case 'preview':
          return content.posterUrl || externalMetadata.thumbnail
        case 'processing':
        case 'empty':
          return ''
        case 'hidden':
        default:
          return undefined
      }
    }

    const displayState = getPosterDisplayState()
    const shouldShowPoster = displayState !== 'hidden'

    React.useEffect(() => {
      setActiveMode(content.mode)
    }, [content.mode])

    // limpia el campo de vista previa si la URL es inválida
    React.useEffect(() => {
      if (!content.url) {
        setExternalMetadata(prevState => ({ ...prevState, previewUrl: '' }))
      }
    }, [content])

    // fetch del video de instagram / tiktok cuando hay una url válida
    React.useEffect(() => {
      const shouldFetch = !state.successfulCards.includes(content.id) && content.url && content.isValidUrl

      if (shouldFetch) {
        const fetchData = async () => {
          const fetchedMedia = content.url ? await fetchExternalMetadata([content.url]) : []
          const data = fetchedMedia[0] || {}

          if (data) {
            setExternalMetadata(data)
            if (!content.hasCustomTitle && data.caption) {
              onFieldChange(content.id, 'title', data.caption)
            }
          }
        }
        fetchData()
      }
    }, [content, state.successfulCards, onFieldChange, fetchExternalMetadata])

    const setStatus = (status?: StatusType) => {
      const isProcessing = status === undefined || status === 'PROCESSING'
      return isProcessing
        ? { text: 'Procesando', color: tokens.colors.primary.brand[500] }
        : { text: 'Listo', color: tokens.colors.supporting.green[700] }
    }

    return (
      <>
        <Card
          key={content.id}
          style={{
            marginBottom: '15px',
            height: 'auto',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            position: 'relative',
          }}
          styles={{
            body: {
              paddingLeft: '20px',
              paddingBottom: '20px',
              paddingTop: '0px',
            },
          }}
        >
          {/* Botón de eliminar en la esquina superior derecha */}
          <>
            {isSaved && (
              <Tooltip title='Estado del video'>
                <Tag
                  style={{
                    height: '20px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '7%',
                    right: '50px',
                    gap: '8px',
                    backgroundColor: tokens.colors.primary.white,
                    fontSize: typography.label.fontSize,
                    padding: '0 10px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {setStatus(content.status).text}
                  <span
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: setStatus(content.status).color,
                    }}
                  />
                </Tag>
              </Tooltip>
            )}
          </>
          {/* Botón de eliminar en la esquina superior derecha */}
          <>
            <Tooltip title='Eliminar Tarjeta'>
              <button
                type='button'
                onClick={handleDeleteClick}
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '5%',
                  right: '5px',
                }}
              >
                <Trash style={{ color: 'red' }} width={14} />
              </button>
            </Tooltip>
            <Modal
              title='Confirmar Eliminación'
              open={deleteModal}
              onCancel={handleCancel}
              footer={[
                <Button onClick={handleCancel}>Cancelar</Button>,
                <SecondaryButton key='cancel' onClick={handleConfirmDelete}>
                  Eliminar
                </SecondaryButton>,
              ]}
            >
              <p>¿Estás seguro de que deseas eliminar esta tarjeta?</p>
            </Modal>
          </>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              paddingTop: '0px',
              alignItems: 'stretch',
            }}
          >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Tabs
                activeKey={activeMode}
                onChange={handleModeChange}
                items={[
                  {
                    key: 'url',
                    label: 'URL',
                    children: (
                      <Form.Item
                        name={`link${content.id}`}
                        rules={[
                          ...(activeMode === 'url' && required
                            ? [{ required: true, message: 'Por favor, ingresa una URL válida.' }]
                            : []),
                          {
                            validator: (_, value) =>
                              value && !validateUrl(value)
                                ? Promise.reject(new Error('Debe ser una URL válida.'))
                                : Promise.resolve(),
                          },
                        ]}
                        style={{ margin: 0 }}
                      >
                        <Input
                          value={content.url}
                          onChange={e => onFieldChange(content.id, 'url', e.target.value)}
                          prefix={<LinkOutlined />}
                          placeholder='Pega tu URL aquí'
                          style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                          disabled={activeMode === 'upload'}
                        />
                      </Form.Item>
                    ),
                  },
                  {
                    key: 'upload',
                    label: 'Subir archivo',
                    children: (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Upload {...handleFileUpload('video')}>
                          <Button
                            icon={uploadingVideo ? <Spin size='small' /> : <UploadIcon width={12} />}
                            disabled={uploadingVideo}
                          >
                            {uploadingVideo ? 'Subiendo...' : 'Subir Video'}
                          </Button>
                        </Upload>
                        {uploadedFileName && (
                          <span style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'green' }}>
                            <CheckCircleOutlined /> {uploadedFileName}
                          </span>
                        )}
                      </div>
                    ),
                  },
                  {
                    key: 'history',
                    label: 'Historial',
                  },
                ]}
              />

              <Form.Item
                name={`cta${content.id}`}
                rules={[
                  ...(required ? [{ required: true, message: 'Por favor, ingresa un CTA válido.' }] : []),
                  {
                    validator: (_, value) =>
                      value && !validateUrl(value)
                        ? Promise.reject(new Error('El CTA debe ser una URL válida.'))
                        : Promise.resolve(),
                  },
                ]}
                style={{ margin: 0 }}
              >
                <Input
                  value={content.cta}
                  onChange={e => onFieldChange(content.id, 'cta', e.target.value)}
                  onFocus={handleFocusCTA}
                  onBlur={handleBlurCTA}
                  placeholder='Ingresa el enlace del CTA'
                  style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                />
              </Form.Item>

              <Form.Item
                name={`title${content.id}`}
                rules={[
                  {
                    required: required,
                    message: 'Ingresar un título válido',
                  },
                ]}
                style={{ margin: 0 }}
              >
                <Input
                  value={content.title}
                  onChange={e => onFieldChange(content.id, 'title', e.target.value)}
                  onBlur={handleBlurTitle}
                  placeholder='Título del video'
                  style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
                />
              </Form.Item>
            </div>

            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              {shouldShowPoster && <Poster image={getPosterImage(displayState)} state={displayState} />}
            </div>

            {/* Ícono de validación a la derecha de InstagramPreview */}
            {isSaved && (
              <Tooltip title='Contenido guardado'>
                <CheckCircleOutlined
                  style={{
                    color: '#119400',
                    marginLeft: '8px',
                    fontSize: '16px',
                    position: 'absolute',
                    right: '-24px',
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Card>

        <ReuseVideosModal
          visible={reuseVideoModal}
          onClose={() => setReuseVideoModal(false)}
          onSelect={handleExistingVideoSelect}
        />
      </>
    )
  },
  (prevProps, nextProps) => {
    // compara solo los campos que afectan el renderizado
    const prevInput = prevProps.content
    const nextInput = nextProps.content

    return (
      prevInput.url === nextInput.url &&
      prevInput.id === nextInput.id &&
      prevInput.order === nextInput.order &&
      prevInput.cta === nextInput.cta &&
      prevInput.title === nextInput.title &&
      prevProps.isSaved === nextProps.isSaved
    )
  },
)
