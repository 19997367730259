import React from 'react'
import dayjs from 'dayjs'
import { Button, Flex, FloatButton, Form, DatePicker, Row, Col, Modal, Typography, message, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Calendar, Plus, Save, RotateCcw } from 'lucide-react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'

import { tokens } from '../theme/tokens'
import { CardState } from '../../types/card'
import { useCardContext, createInitialCards, INITIAL_REQUIRED_CARDS } from '../context/card'

import { useFetchCarouselData } from '../hooks/carousel/useFetchCarouselData'
import { useFetchCarouselDates } from '../hooks/carousel/useFetchCarouselDates'
import { useSaveCarouselContent } from '../hooks/carousel/useSaveCarouselContent'
import { useFetchMedia } from '../hooks/use-fetch-media'

import { SortableCard } from '../components/content/sortable-card'
import { HelpModal } from '../components/content/help-modal'
import { SecondaryButton } from '../components/ui/secondary-button'
import validateUrl from '../utils/url-validator'
import formatDate from '../utils/date-formatter'
import sanitizeUrl from '../utils/url-sanitizer'
import { CarouselContent, StatusType } from '../../../types/carousel'
import { VALID_TIKTOK_LINK } from '../constants/media-platform'

const MEDIA_DOMAIN = process.env.REACT_APP_MEDIA_DOMAIN

const today = dayjs()

type PosterState = 'empty' | 'processing' | 'ready'

const getPosterState = (carouselItem: { link?: string; status?: StatusType; posterUrl?: string }): PosterState => {
  if (!carouselItem.link) return 'empty'
  if (carouselItem.status === 'READY') return 'ready'
  return 'processing'
}
const getDomain = (link: string): 'media' | 'instagram' | 'tiktok' | undefined => {
  if (link.includes(MEDIA_DOMAIN as string)) return 'media'
  if (link.includes('instagram.com')) return 'instagram'
  if (link.includes('tiktok.com')) return 'tiktok'
  return undefined
}

export const ContentPage: React.FC<{ widgetId: string }> = ({ widgetId }) => {
  const [form] = Form.useForm()
  const date = Form.useWatch('date', form)

  const { state, dispatch } = useCardContext()
  const { initialCards, cards, isFormModified } = state

  const { fetchSubmittedDates, submittedDates } = useFetchCarouselDates({ widgetId })
  const { fetchCarouselData } = useFetchCarouselData({ widgetId })
  const { saveCarouselContent } = useSaveCarouselContent({ widgetId })
  const { fetchExternalMetadata } = useFetchMedia()

  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isHelpModalOpen, setHelpModalOpen] = React.useState<boolean>(false)
  const [isFormCompleted, setIsFormCompleted] = React.useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    dispatch({ type: 'DRAG_END', payload: event })
  }

  const handleAddCard = () => {
    dispatch({ type: 'ADD_CARD' })
  }

  const handleDeleteCard = (id: string) => {
    dispatch({ type: 'DELETE_CARD', payload: id })
  }

  const handleResetForm = () => {
    dispatch({ type: 'SET_INITIAL_CARDS', payload: initialCards })
    closeResetModal()
  }

  const closeResetModal = () => setIsModalVisible(false)

  const openResetModal = () => setIsModalVisible(true)

  const fetchCarouselContentByDate = React.useCallback(
    async (selectedDate: dayjs.Dayjs) => {
      try {
        const content = await fetchCarouselData(selectedDate)

        if (!content.length) {
          dispatch({ type: 'SET_INITIAL_CARDS', payload: createInitialCards(INITIAL_REQUIRED_CARDS) })
          return
        }

        const formValues: Record<string, string> = {}

        const instagramUrls = content
          .filter(item => item.link?.includes('instagram.com'))
          .map(item => item.link as string)

        const tiktokUrls = content
          .filter(item => VALID_TIKTOK_LINK.test(item.link || ''))
          .map(item => item.link as string)

        const allUrls = [...instagramUrls, ...tiktokUrls]

        const fetchedMedia = allUrls.length > 0 ? await fetchExternalMetadata(allUrls) : []

        const initialCards = content.map((carouselItem, index) => {
          const domain = getDomain(carouselItem.link || '')
          const posterState = getPosterState(carouselItem)
          let posterUrl = ''

          if (posterState === 'ready') {
            posterUrl = carouselItem.posterUrl || ''
          } else if (posterState === 'processing') {
            const media = fetchedMedia.find(item => item.mediaUrl === carouselItem.link)
            posterUrl = media?.thumbnail || ''
          }

          const input = {
            id: carouselItem.id,
            order: carouselItem.order,
            url: carouselItem.link || '',
            cta: carouselItem.cta || '',
            title: carouselItem.title || '',
            posterUrl,
            isValidUrl: !!carouselItem.link,
            hasCustomCta: !!carouselItem.cta,
            hasCustomTitle: !!carouselItem.title,
            mode: domain === 'media' ? ('upload' as 'upload') : ('url' as 'url'),
            status: carouselItem.status,
          }

          formValues[`link${input.id}`] = input.url
          formValues[`cta${input.id}`] = input.cta
          formValues[`title${input.id}`] = input.title

          return input
        })

        dispatch({ type: 'SET_INITIAL_CARDS', payload: initialCards })
        form.setFieldsValue(formValues)
      } catch (error) {
        if (error instanceof Error && error.message?.includes('404')) {
          dispatch({ type: 'SET_INITIAL_CARDS', payload: createInitialCards(INITIAL_REQUIRED_CARDS) })
        } else {
          console.error('An unexpected error occurred:', error)
        }
      }
    },
    [form, fetchCarouselData, dispatch, fetchExternalMetadata],
  )

  const handleSubmit = async (values: any) => {
    const date = formatDate(values.date, today)

    if (!hasUniqueUrls(cards)) {
      message.error('No se pueden usar URLs duplicadas')
      return
    }

    try {
      const content = cards.map((input): CarouselContent => {
        const link = input.mode === 'url' ? sanitizeUrl(values[`link${input.id}`]) : input.url

        return {
          id: input.id,
          order: input.order,
          link,
          cta: values[`cta${input.id}`],
          title: values[`title${input.id}`],
          posterUrl: input.posterUrl,
          status: input.status,
        }
      })
      const payload = { content }

      const success = await saveCarouselContent(date, payload)

      if (success) {
        message.success('Contenido guardado correctamente')

        const cardUpdates = content.map(item => {
          const isMediaItem = getDomain(item.link) === 'media'

          return {
            ...item,
            url: item.link,
            isValidUrl: !!item.link,
            hasCustomCta: !!item.cta,
            hasCustomTitle: !!item.title,
            mode: isMediaItem ? ('upload' as 'upload') : ('url' as 'url'),
            status: item.status,
          }
        })

        dispatch({
          type: 'SET_INITIAL_CARDS',
          payload: cardUpdates,
        })
      }
    } catch (error) {
      message.error('Ocurrió un error al guardar el contenido')
      console.error('An unexpected error occurred:', error)
    }
  }

  const handleInputChange = React.useCallback(
    (id: string, field: 'url' | 'cta' | 'title' | 'posterUrl', value: string) => {
      const card = cards.find(c => c.id === id)
      const updates: Partial<CardState> = { [field]: value }

      if (field === 'url') {
        updates.isValidUrl = card?.mode === 'upload' ? true : validateUrl(value)

        if (!value) {
          updates.title = ''
          updates.cta = ''
          updates.hasCustomTitle = false
          updates.posterUrl = ''
        } else {
          updates.cta = value
        }
      } else if (field === 'cta') {
        updates.cta = value
        updates.isValidUrl = validateUrl(value)
      } else if (field === 'title') {
        updates.title = value?.replace(/\n+/g, ' ') || ''
        updates.hasCustomTitle = value.trim() !== '' || !!cards.find(card => card.id === id)?.hasCustomTitle
      }

      dispatch({
        type: 'UPDATE_CARD',
        payload: { id, updates },
      })
    },
    [cards, dispatch],
  )

  // verifica si hay urls duplicadas
  const hasUniqueUrls = (cards: CardState[]): boolean => {
    const urls = cards.map(card => card.url.trim()).filter(url => url !== '')
    return new Set(urls).size === urls.length
  }

  // verifica si la fecha ya fue enviada
  const isSubmittedDate = (currentDate: dayjs.Dayjs): boolean => {
    if (!currentDate) return false
    return submittedDates.includes(formatDate(currentDate))
  }

  // verifica si el form esta completo
  const checkFormCompletion = React.useCallback(() => {
    const completedCards = cards.every(c => c.url.trim() !== '' && c.cta.trim() !== '' && c.title.trim() !== '')

    setIsFormCompleted(completedCards && !!date)
  }, [cards, date])

  // inicializa el estado de las tarjetas
  React.useEffect(() => {
    const formValues: Record<string, string> = {}
    cards.forEach(input => {
      formValues[`link${input.id}`] = input.url
      formValues[`cta${input.id}`] = input.cta
      formValues[`title${input.id}`] = input.title
    })
    form.setFieldsValue(formValues)
  }, [cards, form])

  // carga el contenido del carrusel por fecha
  React.useEffect(() => {
    if (date) {
      fetchCarouselContentByDate(date)
    }
  }, [date, fetchCarouselContentByDate])

  // carga las fechas enviadas
  React.useEffect(() => {
    if (widgetId) {
      fetchSubmittedDates()
    }
  }, [widgetId, fetchSubmittedDates])

  // muestra el modal de ayuda la primera vez que se carga la página
  React.useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedEditForm')
    if (!hasVisited) {
      setHelpModalOpen(true)
      localStorage.setItem('hasVisitedEditForm', 'true')
    }
  }, [])

  // verifica si el formulario está completo
  React.useEffect(() => {
    checkFormCompletion()
  }, [cards, checkFormCompletion])

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Flex vertical gap='middle' style={{ height: '100%', width: '100%', overflowX: 'hidden', paddingTop: '1em' }}>
        <Row gutter={[16, 16]} justify='start'>
          <Col xs={{ span: 24, offset: 1 }} lg={{ span: 12, offset: 6 }}>
            <Form layout='vertical' form={form} onFinish={handleSubmit} requiredMark={false}>
              <Flex justify='space-between' style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <div>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Carga tus contenidos
                  </Typography.Title>
                </div>

                {/*  Date selector */}
                <div>
                  <Form.Item
                    name='date'
                    rules={[{ type: 'object', required: true, message: 'Seleccionar una fecha' }]}
                    style={{ marginBottom: 0, flexGrow: 1, width: '100%' }}
                    initialValue={today}
                  >
                    <DatePicker
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        padding: '8px 16px',
                      }}
                      className='custom-placeholder'
                      placeholder='Seleccionar fecha'
                      suffixIcon={<Calendar width={14} style={{ color: '#344054' }} />}
                      cellRender={currentDate => {
                        const dayjsDate = dayjs(currentDate)
                        const isDateWithContent = isSubmittedDate(dayjsDate)
                        return (
                          <div
                            style={
                              isDateWithContent
                                ? {
                                    color: '#FFFFFF',
                                    backgroundColor: tokens.colors.supporting.green[500],
                                    borderRadius: '50%',
                                    width: '25px',
                                    height: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: '7px',
                                    fontWeight: 'bold',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                  }
                                : undefined
                            }
                          >
                            {dayjsDate.date()}
                          </div>
                        )
                      }}
                    />
                  </Form.Item>
                </div>
              </Flex>

              {/* cards */}
              <SortableContext items={cards.map(c => c.id)} strategy={rectSortingStrategy}>
                {cards.map((card, index) => (
                  <SortableCard
                    key={card.id}
                    content={card}
                    required={index < INITIAL_REQUIRED_CARDS}
                    onFieldChange={handleInputChange}
                    isSaved={state.successfulCards.includes(card.id)}
                    onDelete={handleDeleteCard}
                  />
                ))}
              </SortableContext>

              {/* agregar tarjeta */}
              <Form.Item>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <SecondaryButton onClick={handleAddCard} style={{ width: '100%' }} icon={<Plus width={15} />}>
                    Agregar tarjeta
                  </SecondaryButton>
                </div>
                {/* botón de guardar */}
                <div
                  style={{
                    position: 'fixed',
                    top: '80%',
                    right: '6%',
                    transform: 'translateY(-50%)',
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip
                    title={
                      !isFormCompleted
                        ? 'Recuerda que necesitas completar 4 tarjetas y no repetir URLs para poder enviar contenido'
                        : ''
                    }
                    placement='top'
                  >
                    <div className='fixed right-[6%] top-[80%] z-[1000] -translate-y-1/2'>
                      <Button
                        htmlType='submit'
                        disabled={!isFormCompleted || !isFormModified}
                        icon={<Save width={15} />}
                        style={{
                          width: '200px',
                          height: '45px',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        Guardar Contenido
                      </Button>
                    </div>
                  </Tooltip>
                </div>

                {/* botón restablecer con modal */}
                <div
                  style={{
                    position: 'fixed',
                    top: '90%',
                    right: '6%',
                    transform: 'translateY(-50%)',
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip title='Deshacer cambios' placement='top'>
                    <div>
                      <SecondaryButton
                        type='default'
                        icon={<RotateCcw width={15} />}
                        onClick={openResetModal}
                        disabled={!isFormModified}
                        style={{
                          width: '200px',
                          height: '45px',
                          fontSize: '14px',
                          fontWeight: 600,
                          border: !isFormModified ? '2px solid transparent' : '2px solid red',
                          color: !isFormModified ? 'rgba(0, 0, 0, 0.25)' : 'red',
                          cursor: !isFormModified ? 'not-allowed' : 'pointer',
                        }}
                      >
                        Restablecer
                      </SecondaryButton>
                    </div>
                  </Tooltip>
                  <Modal
                    title='Restablecer'
                    open={isModalVisible}
                    onCancel={closeResetModal}
                    footer={[
                      <Button key='cancel' onClick={closeResetModal}>
                        Cancelar
                      </Button>,
                      <Button key='reset' type='primary' danger onClick={handleResetForm}>
                        Restablecer
                      </Button>,
                    ]}
                  >
                    <p>
                      ¿Estás seguro de que deseas restablecer el formulario a su estado inicial? Todos los cambios se
                      perderán.
                    </p>
                  </Modal>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Flex>
      {/* botón de ayuda con modal */}
      <FloatButton
        icon={<QuestionCircleOutlined />}
        style={{ right: 50, top: 24 }}
        onClick={() => setHelpModalOpen(true)}
      />
      <HelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
    </DndContext>
  )
}
